import _ from 'lodash';

import {
  ApiResListsType,
  ApiResponseType,
  OhsApiRequestName,
  RequestType,
} from 'global-services/api/OhsApiModels';
import { ModuleType } from 'global-services/constants/OhsObject';
import OhsCorrespondenceListsRecord, {
  OhsCorrespondenceFilterPayload,
} from 'correspondence/models/OhsCorrespondenceRecord';
import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { getAttachmentList } from 'global-components/form/fields/attachments/OhsAttachmentServices';
import { cusvalDefCreate, cusvalDefEdit, cusvalDefFetch } from 'admin2/OhsAdmin2Services';
// eslint-disable-next-line import/no-cycle
import { fetchCusvalValue } from 'global-components/form/cusvals/OhsCusvalServices';
import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';

import OhsCorrespondenceExchangeRecord from './models/OhsCorrespondenceExchangeRecord';
import OhsCorrespondenceContractorLookUp from './models/OhsCorrespondenceContractorLookUp';
import OhsCorrespondenceTemplate from './models/OhsCorrespondenceTemplate';
import OhsCorrespondenceTemplateContendType from './models/OhsCorrespondenceTemplateContendType';
import OhsFetchAll from '../global-services/OhsFetchAll';

const contactOptions = (
  _id: string,
  businessName: string,
  contactName: string | null,
  contactEmails: string[] | null,
  classifications: string[],
  tier: any
) =>
  contactEmails?.map(
    (email, i) =>
      new OhsCorrespondenceContractorLookUp(
        _id,
        businessName,
        contactName,
        email,
        classifications,
        tier,
        i === 0
      )
  );

const parseContraLookupList = ({ items, tiers }: { items: any; tiers: any }) => {
  return items.reduce((result: any, companyDetails: any) => {
    const companyContacts =
      companyDetails[7]
        ?.map((contractorDetails: any) =>
          contactOptions(
            companyDetails?.[0],
            companyDetails?.[2],
            contractorDetails?.[0],
            contractorDetails?.[2],
            [companyDetails?.[3], companyDetails?.[5]],
            tiers[companyDetails[1]]
          )
        )
        .flat() ?? [];
    return result.concat(companyContacts);
  }, []);
};

const getCorrespondenceLists = (
  filterInfo: Partial<OhsCorrespondenceFilterPayload>,
  fetchType?: string
): Promise<ApiResponseType<ApiResListsType<OhsCorrespondenceListsRecord[]>> | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    fetchType as OhsApiRequestName,
    {
      filter: filterInfo,
    }
  );
};

export const getHrLookupList = async (filter?: any): Promise<ApiResListsType<any[]> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Correspondence, OhsApiRequestName.HRLookup, {
    filter,
  });
};

export const getAllHrLookupList = async () => {
  const res: any = await OhsFetchAll(getHrLookupList, {
    sort: {
      order: 1,
      key: '_id',
    },
    archived: false,
  });
  return res;
};

export const getCorrespondence = async (_id: string): Promise<OhsCorrespondenceListsRecord> => {
  const res: OhsCorrespondenceListsRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    OhsApiRequestName.FetchRoll,
    { _id }
  );
  return res || new OhsCorrespondenceListsRecord();
};

export const addCorrespondenceTemplate = (payload: any) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    OhsApiRequestName.CreateProse,
    payload
  );
};
export const addCorrespondence = (payload: any) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    OhsApiRequestName.CreateRoll,
    payload
  );
};
export const editCorrespondence = (payload: any) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    OhsApiRequestName.EditRoll,
    payload
  );
};

export const editCorrespondenceTemplate = (
  payload: any
): Promise<OhsCorrespondenceTemplate | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    OhsApiRequestName.EditProse,
    payload
  );
};

export const archiveCorrespondence = (_id: string, archived: boolean) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    archived ? OhsApiRequestName.ArchiveRoll : OhsApiRequestName.UnArchiveRoll,
    {
      _id,
    }
  );
};
export const archiveCorrespondenceTemplate = (templateId: string, archived: boolean) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    archived ? OhsApiRequestName.ArchiveProse : OhsApiRequestName.UnArchiveProse,
    {
      _id: templateId,
    }
  );
};
export const archiveCorrespondenceForm = (formId: string, archived: boolean) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    archived ? OhsApiRequestName.ArchiveForm : OhsApiRequestName.UnArchiveForm,
    {
      _id: formId,
    }
  );
};

export const deleteCorrespondence = (id: string) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    OhsApiRequestName.DeleteRoll,
    {
      _id: id,
    }
  );
};

export const getCorrespondenceStatus = (id: string): Promise<any[] | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    OhsApiRequestName.StatusRoll,
    {
      _id: id,
    }
  );
};

export const getCorrespondenceExchangeLists = (
  filterInfo: OhsCorrespondenceFilterPayload,
  _id: string
): Promise<ApiResponseType<ApiResListsType<OhsCorrespondenceExchangeRecord[]>> | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    OhsApiRequestName.ListExchange,
    {
      roll: {
        _id,
      },
      filter: filterInfo,
    }
  );
};

export const searchCorrespondenceExchangeLists = (
  searchData: any
): Promise<ApiResponseType<ApiResListsType<any[]>> | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    OhsApiRequestName.SearchExchange,
    {
      ...searchData,
    }
  );
};

export const getCorrespondenceExchange = async (
  exchange_id: string,
  roll_id: string,
  link?: { type: ModuleType.HR | ModuleType.Contractor; _id: string }
): Promise<any | null> => {
  const payload = {
    roll: {
      _id: roll_id,
    },
    exchange: {
      _id: exchange_id,
    },
  } as any;
  if (link) {
    payload.link = link;
  }
  const exchange = (await OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    OhsApiRequestName.FetchExchange,
    payload
  )) as any;

  const allAttachmentIds: string[] = [];
  for (let index = 0; index < exchange.exchanges.length; index += 1) {
    const ex: any = exchange.exchanges[index];
    if (_.has(ex, 'form.cusvals._id') && ex.form.cusvals?._id) {
      // eslint-disable-next-line no-await-in-loop
      const cusDef = (await cusvalDefFetch({ _id: ex.form.cusvals._id })) as any;
      if (cusDef && cusDef.cusvals) ex.form.cusvals = cusDef.cusvals;
    }
    if (ex.cusvals) {
      // eslint-disable-next-line no-await-in-loop
      const cusvals = (await fetchCusvalValue(ex.cusvals._id)) as any;
      if (cusvals) {
        ex.cusvals = cusvals;
      }
    }
    if (_.has(ex, 'prose.content')) {
      for (let contentIndex = 0; contentIndex < ex.prose.content.length; contentIndex += 1) {
        const content: any = ex.prose.content[contentIndex];

        if (
          content &&
          content.type === OhsCorrespondenceTemplateContendType.OhsAttachment &&
          _.has(content, 'data.attachments')
        ) {
          if (content.data.attachments?.length) {
            allAttachmentIds.push(
              ...(content.data.attachments?.map((att: OhsAttachment) => att?._id) ?? [])
            );
          }
        }
      }
    }
    if (ex.attachments && ex.attachments.length) {
      allAttachmentIds.push(...(ex.attachments.map((att: OhsAttachment) => att?._id) ?? []));
    }
    if (ex.signatures && ex.signatures.length) {
      allAttachmentIds.push(
        ...(ex.signatures.map((signature: any) => signature.attachments[0]?._id) ?? [])
      );
    }
  }

  const allAttachmentList = await getAttachmentList(allAttachmentIds);

  // Create a lookup map for attachment details
  const attachmentLookup = allAttachmentList.reduce((acc, attachment) => {
    if (attachment._id) {
      acc[attachment._id] = attachment;
    }
    return acc;
  }, {} as { [key: string]: OhsAttachment });

  // Function to replace IDs with attachment details directly within attachmentsObject
  const replaceIdsWithDetailsInPlace = (attachmentIdsArray: string[]) => {
    return attachmentIdsArray.map((id) => attachmentLookup[id] || null);
  };

  // Update exchange with detailed attachment info which is necessary before returning exchange
  exchange.exchanges.forEach((ex: any) => {
    if (_.has(ex, 'prose.content')) {
      ex.prose.content.forEach((content: any) => {
        if (
          content &&
          content.type === OhsCorrespondenceTemplateContendType.OhsAttachment &&
          _.has(content, 'data.attachments')
        ) {
          if (content.data.attachments?.length) {
            const attList = replaceIdsWithDetailsInPlace(
              content.data.attachments?.map((att: OhsAttachment) => att?._id)
            );
            content.data.attachments = attList;
            content.data = { ...content.data, ...attList[0] };
          }
        }
      });
    }

    if (ex.attachments && ex.attachments.length) {
      ex.attachments = replaceIdsWithDetailsInPlace(
        ex.attachments.map((att: OhsAttachment) => att._id)
      );
      ex.attachments.forEach((att: any) => {
        att.downloadLink = att.url;
      });
    }
    if (ex.signatures && ex.signatures.length) {
      ex.signatures.forEach((signature: any) => {
        if (signature.attachments && signature.attachments.length) {
          const updatedAttachments = replaceIdsWithDetailsInPlace([signature.attachments[0]?._id]);
          // Update the whole signature object inside ex.signature foreach
          Object.assign(signature, { attachments: updatedAttachments }, ...updatedAttachments);
        }
      });
    }
  });

  return exchange;
};

export const getCorrespondenceExchangeRecordById = async (
  exchange_id: string,
  roll_id: string
): Promise<any | null> => {
  const payload = {
    roll: {
      _id: roll_id,
    },
    exchange: {
      _id: exchange_id,
    },
  } as any;
  const exchange = (await OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    OhsApiRequestName.FetchExchange,
    payload
  )) as any;

  return exchange;
};

export const getContractorLookupList = async (
  filter?: any
): Promise<ApiResListsType<any[]> | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    OhsApiRequestName.ContractorLookup,
    {
      filter,
    }
  );
};

export const getAllContractorLookupList = async () => {
  const res: any = await OhsFetchAll(getContractorLookupList, {
    sort: {
      order: 1,
      key: '_id',
    },
    archived: false,
  });

  const parsedList = parseContraLookupList(res);
  return parsedList;
};

export const addCorrespondenceExchange = (data: any) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    OhsApiRequestName.CreateExchange,
    {
      roll: data.roll,
      tzDateCreated: data.tzDateCreated,
      recipients: data.recipients,
    }
  );
};
export const editCorrespondenceExchange = (data: any) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    OhsApiRequestName.EditExchange,
    {
      op: data.op,
      _ids: data._ids,
      roll: data.roll,
      tz: data.tz,
    }
  );
};

export const schdeuleCorrespondenceExchange = (data: any) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    OhsApiRequestName.ScheduleExchange,
    {
      schedule: data.schedule,
      _ids: data._ids,
      roll: data.roll,
      tzDateCreated: data.tzDateCreated,
    }
  );
};

export const cancelSchdeuleCorrespondenceExchange = (data: any) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    OhsApiRequestName.CancelScheduleExchange,
    {
      _ids: data._ids,
      roll: data.roll,
    }
  );
};
export const archiveCorrespondenceExchange = (
  rollId: string,
  exchangeIds: string[],
  archived: boolean
) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    archived ? OhsApiRequestName.ArchiveExchange : OhsApiRequestName.UnArchiveExchange,
    {
      _ids: exchangeIds,
      roll: {
        _id: rollId,
      },
    }
  );
};

export const deleteCorrespondenceExchange = (ids: string[], rollId: string) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    OhsApiRequestName.DeleteExchange,
    {
      _ids: ids,
      roll: {
        _id: rollId,
      },
    }
  );
};

export const getCorrespondenceTemplate = async (
  id: string
): Promise<OhsCorrespondenceTemplate | null> => {
  const res: OhsCorrespondenceTemplate | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    OhsApiRequestName.ProseFetch,
    {
      _id: id,
    }
  );
  if (res && res.content && res.content.length) {
    const ids: string[] = [];
    res.content.forEach((item) => {
      if (
        item.type === OhsCorrespondenceTemplateContendType.OhsAttachment &&
        item.data.attachments &&
        item.data.attachments[0] &&
        item.data.attachments[0]._id
      ) {
        ids.push(item.data.attachments[0]._id);
      }
    });
    if (ids.length) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      const attachments = await getAttachmentList(ids);
      res.content.forEach((item) => {
        if (
          item.type === OhsCorrespondenceTemplateContendType.OhsAttachment &&
          item.data.attachments &&
          item.data.attachments[0] &&
          item.data.attachments[0]._id
        ) {
          const att = _.find(attachments, { _id: item.data.attachments[0]._id });
          _.assign(item.data, att);
        }
      });
    }
  }

  return res;
};

export const addCorrespondenceForm = async (payload: any) => {
  if (payload.cusvals && payload.cusvals.length) {
    const cusvals = (await cusvalDefCreate({ cusvals: payload.cusvals })) as any;
    if (cusvals) {
      payload.cusvals = { _id: cusvals._id, type: cusvals.type };
    }
  } else {
    payload.cusvals = null;
  }
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    OhsApiRequestName.FormCreate,
    payload
  );
};
export const editCorrespondenceForm = async (payload: any) => {
  if (payload.cusvals && payload.cusvals.length) {
    let cusvals = null;
    if (payload.cusvalsDef) {
      cusvals = (await cusvalDefEdit({
        _id: payload.cusvalsDef._id,
        cusvals: payload.cusvals,
      })) as any;
    } else {
      cusvals = (await cusvalDefCreate({ cusvals: payload.cusvals })) as any;
    }

    if (cusvals) {
      payload.cusvals = { _id: cusvals._id, type: cusvals.type };
    }
  } else {
    payload.cusvals = null;
  }
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    OhsApiRequestName.FormEdit,
    payload
  );
};
export const fetchCorrespondenceForm = async (id: string) => {
  const payload = { _id: id };
  const form = (await OhsApiRequest(
    RequestType.Module,
    ModuleType.Correspondence,
    OhsApiRequestName.FormFetch,
    payload
  )) as any;
  if (form.cusvals) {
    const cusvals = (await cusvalDefFetch({ _id: form.cusvals._id })) as any;

    if (cusvals) {
      form.cusvalsDef = cusvals;
      form.cusvals = cusvals.cusvals;
    }
  }
  return form;
};

export default getCorrespondenceLists;
