import React from 'react';

import { Routes, Route, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { toast } from 'react-toastify';
import shortid from 'shortid';

import OhsTheme from 'global-services/styles/OhsTheme';
import { ModuleRoute, ModuleUrl } from 'global-services/constants/OhsRoutes';
import OhsLoginHome from 'login/OhsLoginHome';
import OhsIframeModulePublicHome from 'global-components/angular-iframe/OhsIframeModulePublicHome';
import OhsRootHome from 'login/OhsRootHome';
import OhsResetPasswordRequest from 'login/password/OhsResetPasswordRequest';
import OhsResetPassword from 'login/password/OhsResetPassword';
import OhsLinkAccessRoutes from 'linkaccess/OhsLinkAccessRoutes';
import OhsApiServices from 'global-services/api/OhsApiServices';
import OhsLoginServices from 'login/OhsLoginServices';
import {
  downloadAttachment,
  getAttachmentList,
  uploadAttachment,
} from 'global-components/form/fields/attachments/OhsAttachmentServices';
import { getCurrentTimezone } from 'global-services/OhsDataParse';
import OhsStandardCountries from 'global-services/constants/code/OhsStandardCountries';
import { generatedStateOptions } from 'admin2/OhsAdmin2Services';
import OhsVisitorRouteList from 'visitor/OhsVisitorRouteList';
import OhsVisitorUnLoginSignInHome from 'visitor/site/unlogin-sign/OhsVisitorUnLoginSignInHome';
import OhsCorrespondenceLinkAccessHome from 'linkaccess/correspondence/OhsCorrespondenceLinkAccessHome';
import OhsCorrespondenceLinkAccessServices from 'linkaccess/correspondence/OhsCorrespondenceLinkAccessServices';
import addWatermarkToSignature from 'global-services/watermark/addWatermarkToSignature';
import { base64ToBlob } from 'global-services/utils/OhsUtility';
import { uploadSignature } from 'global-components/form/fields/signature/OhsSignatureServices';
import OhsUserDataServices from 'user/OhsUserDataServices';
import OhsInspectionLinkAccessServices from 'linkaccess/inspection/OhsInspectionLinkAccessServices';
import OhsInspectionLinkAccessHome from 'linkaccess/inspection/OhsInspectionLinkAccessHome';
import OhsUserLocalServices from 'user/OhsUserLocalServices';
import { isMobileDevice } from 'global-services/OhsMobileServices';
import { OhsTaskLinkMobileForward } from 'task/link/OhsTaskLinkMobileForward';

import UrlChangeDetector from './UrlChangeDetector';
import OhsPrivateModuleRoutes from './OhsPrivateModuleRoutes';

export default function OhsModuleRoutes() {
  const user = OhsUserLocalServices.getLocalOhsUser();
  const navigate = useNavigate();
  const isMobileBoolean = isMobileDevice();

  (window as any).moduleUtils = {
    OhsStandardCountries,
    generatedStateOptions,
    getApiRoot: OhsApiServices.getApiRoot(),
    navigate,
    loginByUsernamePassword: OhsLoginServices.loginByUsernamePassword,
    updateSession: OhsApiServices.updateSession,
    resetUserData: OhsUserDataServices.resetUserData,
    shortid,
    addWatermarkToSignature,
    base64ToBlob,
    uploadSignature,
  };

  (window as any).root = {
    getAttachmentList,
    uploadAttachment,
    downloadAttachment,
    toast,
    getCurrentTimezone,
    user,
    OhsCorrespondenceLinkAccessServices,
    OhsInspectionLinkAccessServices,
  };
  return (
    <>
      <UrlChangeDetector />
      <Routes>
        <Route path="" element={<OhsRootHome />} />
        <Route
          path={`/${ModuleUrl.Login}`}
          element={
            <ThemeProvider theme={OhsTheme()}>
              <OhsLoginHome />
            </ThemeProvider>
          }
        />
        {isMobileBoolean && (
          <Route path="/task/link/redirect" element={<OhsTaskLinkMobileForward />} />
        )}
        <Route path="/terms-of-use/*" element={<OhsIframeModulePublicHome />} />
        <Route path="/signup/*" element={<OhsIframeModulePublicHome />} />
        <Route path="/linkaccess/onboarding/*" element={<OhsLinkAccessRoutes />} />
        <Route path="/linkaccess/correspondence/*" element={<OhsCorrespondenceLinkAccessHome />} />
        <Route path="/linkaccess/inspection/*" element={<OhsInspectionLinkAccessHome />} />
        <Route
          path={`/${ModuleUrl.ResetPassword}`}
          element={
            <ThemeProvider theme={OhsTheme()}>
              <OhsResetPasswordRequest />
            </ThemeProvider>
          }
        />
        <Route
          path={`/${ModuleUrl.RecoveryPassword}/:token`}
          element={
            <ThemeProvider theme={OhsTheme()}>
              <OhsResetPassword />
            </ThemeProvider>
          }
        />
        <Route
          path={`/visitor/${OhsVisitorRouteList[ModuleRoute.MobileRedirect].path}`}
          element={<OhsVisitorUnLoginSignInHome />}
        />
        <Route path="*" element={<OhsPrivateModuleRoutes />} />
      </Routes>
    </>
  );
}
