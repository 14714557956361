import React, { useState } from 'react';

import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import 'semantic-ui-css/semantic.min.css';
import { Box, Divider } from '@mui/material';
import { Icon } from 'semantic-ui-react';
import { generatePath, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { SmallScreenWidthBreakpoint } from 'global-components/theme/OhsLayoutConstants';
import {
  toggleLeftSideDrawer,
  toggleTopFilterDrawer,
  getOhsDashboard,
} from 'dashboard/OhsDashboardSlice';
import { shortenName } from 'global-services/utils/OhsUtility';
import OhsQuickInspection from 'inspection/register/OhsQuickInspection';
import ActionRouteList from 'action/OhsActionRoutesList';
import IncidentRouteList from 'incident/OhsIncidentRouteList';
import OhsAdmin2RouteList from 'admin2/OhsAdmin2RouteList';
import OhsUserLocalServices from 'user/OhsUserLocalServices';

import DashboardBannerList from '../banner/item/OhsDashboardBanner';
import DashboardBannerPreview from '../banner/item/OhsDashboardBannerItem';
import OhsLoginServices from '../../login/OhsLoginServices';
import OhsBreadcrumbs from '../breadcrumbs/OhsBreadcrumbs';
import OhsMorphButtons from './OhsMorphButtons';
import { OhsMaterialIconButton } from '../../global-components/buttons/OhsMaterialButton';
import OhsFreemiumTopbar from './OhsFreemiumTopbar';
import OhsPrintButton from './OhsPrintButton';
import OhsLogoutButton from './logoutButton/OhsLogoutButton';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  hideOnSmallScreen: {
    display: 'none',
    [theme.breakpoints.up(SmallScreenWidthBreakpoint)]: {
      display: 'block',
    },
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'flex',
    height: '40px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  signOutButton: {
    marginLeft: theme.spacing(2),
  },
  iconButton: {
    [theme.breakpoints.down(SmallScreenWidthBreakpoint)]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up(SmallScreenWidthBreakpoint)]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  quickAccessButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontWeight: 'bold',
    display: 'none',
    [theme.breakpoints.up(SmallScreenWidthBreakpoint)]: {
      display: 'block',
    },
  },
  avatarButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontWeight: 'bold',
  },
  avatarRoot: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  avatar: {
    color: 'black',
    background: 'white',
  },
  icon: {
    height: theme.spacing(3),
    weight: theme.spacing(3),
  },
  midButton: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  mainToolbar: {
    minHeight: '40px!important',
    background: theme.palette.custom?.HEADER_BAR_BG_COLOR,
  },
  breadcrumbsToolbar: {
    minHeight: '40px!important',
    background: theme.palette.custom?.MAIN_BG_COLOR,
  },
  hideIcon: {
    display: 'none',
  },
}));

export const openResourceCentre = () => {
  window.location.replace('/resourcecentre/home?category=task');
};

export default function OhsTopBar() {
  const { classes } = useStyles();
  const [openInspectionModal, setOpenInspectionModal] = useState(false);

  const user = OhsUserLocalServices.getLocalOhsUser();
  const {
    topBannerTogglePreviewOpen: showBannerPreview,
    topBannerContentPreview: bannerPreviewContent,
  } = useAppSelector(getOhsDashboard);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const toggleLeftSideDrawerLocal = (open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch(toggleLeftSideDrawer(open));
  };

  const handleTopFilterDrawer = (open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch(toggleTopFilterDrawer(open));
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <div>
      <AppBar
        position="static"
        style={{
          minHeight: '40px',
        }}
      >
        <Toolbar className={classes.mainToolbar}>
          {user?.tierNum !== 5 && (
            <>
              <OhsMaterialIconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={toggleLeftSideDrawerLocal(true)}
                size="small"
              >
                <Icon name="content" />
              </OhsMaterialIconButton>
              <Divider orientation="vertical" flexItem />
            </>
          )}

          <OhsMaterialIconButton
            color="inherit"
            size="small"
            className={classes.iconButton}
            onClick={() => {
              navigate(OhsLoginServices.getHomeUrl(user?.tier || null));
            }}
          >
            <Icon name="home" />
          </OhsMaterialIconButton>
          <Divider orientation="vertical" flexItem />
          <OhsMaterialIconButton
            color="inherit"
            size="small"
            className={classes.iconButton}
            onClick={goBack}
          >
            <Icon name="arrow left" />
          </OhsMaterialIconButton>
          <Divider orientation="vertical" flexItem />
          {user?.tierNum && user?.tierNum > 2 && user?.tierNum < 5 && (
            <>
              {user?.configs.incident?.PERMISSIONS.create === true && (
                <>
                  <OhsMaterialIconButton
                    color="inherit"
                    size="small"
                    className={classes.quickAccessButton}
                    onClick={() => {
                      navigate(generatePath(IncidentRouteList.add.url));
                    }}
                  >
                    <Icon name="plus" />
                    &nbsp;&nbsp;Report {user?.configs.incident?.TITLE}
                  </OhsMaterialIconButton>
                  <Divider className={classes.hideOnSmallScreen} orientation="vertical" flexItem />
                </>
              )}

              {user?.configs.action?.PERMISSIONS.create === true && (
                <>
                  <OhsMaterialIconButton
                    color="inherit"
                    size="small"
                    className={classes.quickAccessButton}
                    onClick={() => {
                      navigate(generatePath(ActionRouteList.add.url));
                    }}
                  >
                    <Icon name="plus" />
                    &nbsp;&nbsp;New {user?.configs.action?.TITLE}
                  </OhsMaterialIconButton>
                  <Divider className={classes.hideOnSmallScreen} orientation="vertical" flexItem />
                </>
              )}
              {user?.configs.inspection?.PERMISSIONS.view === true &&
                user?.configs.inspection?.PERMISSIONS.create === true &&
                user?.configs.inspection?.PERMISSIONS.signoff === true && (
                  <>
                    <OhsMaterialIconButton
                      color="inherit"
                      size="small"
                      className={classes.quickAccessButton}
                      onClick={() => setOpenInspectionModal(true)}
                    >
                      <Icon name="plus" />
                      &nbsp;&nbsp;{user?.configs.inspection?.TITLE}
                    </OhsMaterialIconButton>
                    <Divider
                      className={classes.hideOnSmallScreen}
                      orientation="vertical"
                      flexItem
                    />
                  </>
                )}
            </>
          )}

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Divider className={classes.hideOnSmallScreen} orientation="vertical" flexItem />
            {user?.tierNum !== 5 && (
              <Box className={classes.hideOnSmallScreen} mx={1}>
                <div>
                  <div
                    style={{
                      fontSize: '0.9rem',
                      fontWeight: 'bold',
                      marginTop: '3px',
                    }}
                  >
                    {shortenName(user?.name).toUpperCase()}
                  </div>
                  {user?.tierNum === 2 &&
                  user?.isMorph === true &&
                  user?.configs.admin2?.LOWER_TIER_ACCESS &&
                  user?.configs.admin2?.LOWER_TIER_ACCESS[0] ? (
                    <div style={{ fontSize: '0.9rem', marginTop: '-5px' }}>
                      {shortenName(user?.configs.admin2?.LOWER_TIER_ACCESS[0].name)}
                    </div>
                  ) : (
                    <div style={{ fontSize: '0.9rem', marginTop: '-5px' }}>
                      {shortenName(user?.tier?.name)}
                    </div>
                  )}
                </div>
              </Box>
            )}
            {user?.tierNum === 5 && (
              <OhsMaterialIconButton
                color="inherit"
                size="small"
                className={classes.avatarButton}
                style={{
                  fontWeight: 'bold',
                }}
                onClick={() => {
                  navigate(generatePath(OhsAdmin2RouteList.settings.url));
                }}
              >
                <Icon name="user" />

                <span className={classes.hideOnSmallScreen}>
                  &nbsp;&nbsp;
                  {user?.name.length <= 30
                    ? user?.name.toUpperCase()
                    : `${user?.name.substring(0, 30).toUpperCase()}...`}
                </span>
              </OhsMaterialIconButton>
            )}
            {/* <Divider orientation="vertical" flexItem />
            <OhsMaterialIconButton
              color="inherit"
              onClick={() => {
                navigate('/search/home');
              }}
              size="small"
              className={classes.hideIcon} // Hide search icon
            >
              <Icon name="search" />
            </OhsMaterialIconButton> */}
            <Divider orientation="vertical" flexItem />
            <OhsMaterialIconButton
              color="inherit"
              onClick={openResourceCentre}
              size="small"
              className={classes.iconButton}
            >
              <Icon name="help" />
            </OhsMaterialIconButton>
            <Divider orientation="vertical" flexItem />
            {((user?.tierNum === 2 && user?.isMorph === true) ||
              user?.tierNum === 3 ||
              (user?.tierNum === 4 && user?.isMorph === true)) && (
              <>
                <OhsMorphButtons />
                <Divider orientation="vertical" flexItem />
              </>
            )}

            {(user?.tierNum === 3 ||
              (user?.tierNum === 4 && window.location.pathname.indexOf('/task/active') === 0) ||
              (user?.tierNum === 4 &&
                window.location.pathname.indexOf('/safety-plan/home') === 0) ||
              (user?.tierNum === 5 && window.location.pathname.indexOf('/task/active') === 0)) && (
              <>
                <OhsMaterialIconButton
                  color="inherit"
                  onClick={handleTopFilterDrawer(true)}
                  size="small"
                  className={classes.iconButton}
                >
                  <Icon name="filter" />
                </OhsMaterialIconButton>
                <Divider orientation="vertical" flexItem />
              </>
            )}

            <OhsPrintButton />
            <Divider className={classes.hideOnSmallScreen} orientation="vertical" flexItem />
            <OhsLogoutButton />
          </div>
        </Toolbar>
        {user?.level === 'free' && <OhsFreemiumTopbar />}

        {/* This is toggled on specific pages (onMount and onUnmount event) */}
        <DashboardBannerList />
        {showBannerPreview && <DashboardBannerPreview {...bannerPreviewContent} isPreview />}
        <Toolbar className={classes.breadcrumbsToolbar}>
          <OhsBreadcrumbs />
        </Toolbar>
      </AppBar>
      <OhsQuickInspection isOpen={openInspectionModal} setModalOpen={setOpenInspectionModal} />
    </div>
  );
}
