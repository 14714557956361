import { useEffect, useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { useAppDispatch } from 'app/hooks';
import { clearGlobalSearch } from 'search/store/OhsSearchSlice';

function UrlChangeDetector() {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const fullPath = `${location.pathname}${location.search}`;

  const memoizedPathname = useMemo(() => fullPath, [fullPath]);

  useEffect(() => {
    dispatch(clearGlobalSearch());
  }, [memoizedPathname]);

  return null;
}

export default UrlChangeDetector;
