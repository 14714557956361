import Cookies from 'js-cookie';

import { ModuleType } from 'global-services/constants/OhsObject';
import { OhsApiRequestName, RequestType } from 'global-services/api/OhsApiModels';
import { OhsTier } from 'global-services/constants/tier/OhsTier';
import getMixpanelTracker from 'global-services/mixpanel/OhsMixpanel';
import {
  getOhsLocalStorage,
  OhsLocalStoragePrefix,
  SsoFlagName,
} from 'global-services/OhsDataParse';
import { GlobalFilterName } from 'dashboard/topFilter/OhsFilterModels';
import { UserDataLocalStorageName } from 'user/OhsUserModels';
import {
  OhsMultiUserSessionToken,
  OhsSessionToken,
} from 'global-services/constants/OhsStorageNames';
import OhsUserDataServices from 'user/OhsUserDataServices';
import OhsUsersStoredSessions from 'user/session/OhsUsersStoredSessions';
import OhsSessionServices from 'user/session/OhsSessionServices';

import OhsApiRequest from '../global-services/api/OhsApiRequest';
import { OhsMultiUserLoginOption } from './multi/OhsMultiUserLoginOption';

const loginByUsernamePassword = (email: string, password: string) => {
  const OhsUsersSessions = new OhsUsersStoredSessions();

  // get Active token of the account and use it as session token
  const userActiveSession = OhsUsersSessions.getUserSession(email);

  let isMultiUser;
  if (userActiveSession) {
    isMultiUser = userActiveSession.multiUser;
  }

  const sessionKey = isMultiUser ? OhsMultiUserSessionToken : OhsSessionToken;
  return OhsApiRequest(
    RequestType.Admin,
    ModuleType.User,
    OhsApiRequestName.Authenticate,
    {
      email,
      password,
    },
    {
      sessionToken: {
        [sessionKey]: userActiveSession && userActiveSession[sessionKey],
      },
    }
  );
};

const loginBySSO = (token: string, email: string) => {
  const OhsUsersSessions = new OhsUsersStoredSessions();

  // get Active token of the account and use it as session token
  const userActiveSession = OhsUsersSessions.getUserSession(email);
  let isMultiUser;
  if (userActiveSession) {
    isMultiUser = userActiveSession.multiUser;
  }
  const sessionKey = isMultiUser ? OhsMultiUserSessionToken : OhsSessionToken;

  return OhsApiRequest(
    RequestType.Admin,
    ModuleType.User,
    OhsApiRequestName.SsoLogin,
    {
      token,
    },
    {
      sessionToken: {
        [sessionKey]: userActiveSession && userActiveSession[sessionKey],
      },
    }
  );
};

const InactiveSession = () => {
  const optionToken = Cookies.get(OhsMultiUserSessionToken);
  let sessionToken;
  if (optionToken) {
    sessionToken = { [OhsMultiUserSessionToken]: optionToken };
  } else {
    sessionToken = { [OhsSessionToken]: Cookies.get(OhsSessionToken) };
  }
  return OhsApiRequest(
    RequestType.Admin,
    ModuleType.User,
    OhsApiRequestName.AuthenticateInactive,
    {},
    {
      sessionToken,
    }
  );
};

const getHomeUrl = (userTier: OhsTier | null): string => {
  if (userTier) {
    if (
      userTier.type === 'core.tier.T3' ||
      userTier.type === 'core.tier.T4' ||
      userTier.type === 'core.tier.T5'
    ) {
      return '/task/active';
    }
    return '/admin2/home';
  }
  return '/login';
};
const removeGlobalFilterAndUserDate = () => {
  localStorage.removeItem(OhsLocalStoragePrefix + GlobalFilterName);
  localStorage.removeItem(OhsLocalStoragePrefix + UserDataLocalStorageName);
};
const multiUserLogin = (userId: string, token: string) => {
  removeGlobalFilterAndUserDate();
  OhsSessionServices.setPriorActiveToken();

  const response = OhsApiRequest(
    RequestType.Admin,
    ModuleType.User,
    OhsApiRequestName.MultiUser,
    {
      user: {
        _id: userId,
      },
    },
    { sessionToken: { [OhsSessionToken]: token } }
  );

  return response;
};

const logout = (email: string, deleteUserSessionLocalstorage: boolean = false) => {
  const OhsUsersSessions = new OhsUsersStoredSessions();
  const isSso = getOhsLocalStorage(SsoFlagName);

  InactiveSession().then(() => {
    removeGlobalFilterAndUserDate();

    OhsUserDataServices.resetUserData();
    if (deleteUserSessionLocalstorage) {
      if (email) {
        OhsUsersSessions.removeUserSession(email);
      } else {
        OhsUsersSessions.removeUserSessionByToken();
      }
    }
    const mixpanel = getMixpanelTracker();
    mixpanel?.track('Sign out');
    mixpanel?.reset();
    if (isSso === true) {
      window.open('/unlogin.html#logout', '_self');
    } else {
      window.open('/login', '_self');
    }
  });
};

const getUserOptions = async (): Promise<OhsMultiUserLoginOption[]> => {
  const data: {
    options: OhsMultiUserLoginOption[];
  } | null = await OhsApiRequest(
    RequestType.Admin,
    ModuleType.User,
    OhsApiRequestName.MultiUserList,
    {},
    {}
  );
  if (data && data.options && data.options.length) {
    const userSessions = new OhsUsersStoredSessions();
    data.options = userSessions.updateMultiUserOptions(data);
  }

  return data?.options ?? [];
};

const OhsLoginServices = {
  loginByUsernamePassword,
  getHomeUrl,
  loginBySSO,
  logout,
  multiUserLogin,
  getUserOptions,
  InactiveSession,
  removeGlobalFilterAndUserDate,
};
export default OhsLoginServices;
