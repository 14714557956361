import React, { lazy, Suspense } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { Route, Routes } from 'react-router-dom';
import Cookies from 'js-cookie';

import OhsTheme from 'global-services/styles/OhsTheme';
import { ModuleUrl } from 'global-services/constants/OhsRoutes';
import { getOhsUserLocalAndRemote } from 'user/OhsUserServices';
import OhsBackdrop from 'global-components/backdrop/OhsBackdrop';
import OhsNoAccessPage from 'global-services/utils/OhsNoAccessPage';
import OhsDashboardBars from 'dashboard/OhsDashboardBars';
import OhsOnboardingRoutes from 'freemium/OhsOnboardingRoutes';
import { compareStrings } from 'global-services/utils/OhsDateServices';
import CurrencyCodes from 'global-services/constants/CurrencyCodes';
import OhsRequireAuth from 'global-components/OhsRequireAuth';
import { OhsSessionToken } from 'global-services/constants/OhsStorageNames';
import OhsMfaRequire from 'app/OhsMfaRequire';
import { OhsUser } from 'user/OhsUser';
import OhsUserLocalServices from 'user/OhsUserLocalServices';

import OhsPrivateModuleRouteList from './OhsPrivateModuleRouteList';
import OhsDefaultRoute from '../OhsDefaultRoute';

const OhsActionRoutes = lazy(() => import('../../action/OhsActionRoutes'));
const OhsHRRoutes = lazy(() => import('human-resource/OhsHumanResourceRoutes'));
const OhsDocumentsRoutes = lazy(() => import('documents/OhsDocumentsRoutes'));
const OhsAdmin2Routes = lazy(() => import('admin2/OhsAdmin2Routes'));
const OhsDashboardBannerRoutes = lazy(() => import('dashboard/banner/OhsDashboardBannerRoutes'));
const OhsMobileAppAdminRoutes = lazy(() => import('mobile-app-admin/OhsMobileAppAdminRoutes'));
const OhsMfaSettingRoutes = lazy(() => import('mfa-setting/OhsMfaSettingRoutes'));
const OhsTaskRoutes = lazy(() => import('task/OhsTaskRoutes'));
const OhsInspectionRoutes = lazy(() => import('inspection/OhsInspectionRoutes'));
const OhsSafetyPlanRoutes = lazy(() => import('safety-plan/OhsSafetyPlanRoutes'));
const OhsSearchRoutes = lazy(() => import('search/OhsSearchRoutes'));
const OhsContractorRoutes = lazy(() => import('contractor/routes/OhsContractorRoutes'));
const OhsIncidentRoutes = lazy(() => import('incident/OhsIncidentRoutes'));
const OhsReportingRoutes = lazy(() => import('../reports/OhsReportingRoutes'));
const OhsReviewPlanRoutes = lazy(() => import('review-plan/OhsReviewPlanRoutes'));
const OhsChemicalRoutes = lazy(() => import('chemical/OhsChemicalRoutes'));
const OhsExportRoutes = lazy(() => import('export-centre/OhsExportRoutes'));
const OhsVisitorRoutes = lazy(() => import('visitor/OhsVisitorRoutes'));
const OhsCorporateRiskRoutes = lazy(() => import('corporate-risk/routes/OhsCorporateRiskRoutes'));
const OhsCorrespondenceRoutes = lazy(() => import('correspondence/OhsCorrespondenceRoutes'));
const OhsAssetRoutes = lazy(() => import('assets/OhsAssetRoutes'));
const OhsNoticeBoardRoutes = lazy(() => import('notice-board/OhsNoticeBoardRoutes'));
const OhsXsiRoutes = lazy(() => import('xsi/OhsXsiRoutes'));
const OhsLinkAccessXsiRoutes = lazy(() => import('linkaccess/xsi/OhsLinkAccessXsiRoutes'));
const OhsResourceCentreRoutes = lazy(() => import('resource-centre/OhsResourceCentreRoutes'));
const OhsFeedBackForm = lazy(() => import('resource-centre/feedback/OhsFeedbackForm'));

const ROUTES = [
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.Admin2].path,
    element: <OhsAdmin2Routes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.Admin2].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.DashboardBanner].path,
    element: <OhsDashboardBannerRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.DashboardBanner].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.MobileAppAdmin].path,
    element: <OhsMobileAppAdminRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.MobileAppAdmin].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.MFASetting].path,
    element: <OhsMfaSettingRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.MFASetting].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.Task].path,
    element: <OhsTaskRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.Task].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.Inspection].path,
    element: <OhsInspectionRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.Inspection].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.SafetyPlan].path,
    element: <OhsSafetyPlanRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.SafetyPlan].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.HR].path,
    element: <OhsHRRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.HR].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.Documents].path,
    element: <OhsDocumentsRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.Documents].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.Search].path,
    element: <OhsSearchRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.Search].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.Contractor].path,
    element: <OhsContractorRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.Contractor].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.Action].path,
    element: <OhsActionRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.Action].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.Incident].path,
    element: <OhsIncidentRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.Incident].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.Chemical].path,
    element: <OhsChemicalRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.Chemical].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.Reporting].path,
    element: <OhsReportingRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.Reporting].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.ReviewPlan].path,
    element: <OhsReviewPlanRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.ReviewPlan].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.Export].path,
    element: <OhsExportRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.Export].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.Noticeboard].path,
    element: <OhsNoticeBoardRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.Noticeboard].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.Visitor].path,
    element: <OhsVisitorRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.Visitor].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.CorporateRisk].path,
    element: <OhsCorporateRiskRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.CorporateRisk].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.Correspondence].path,
    element: <OhsCorrespondenceRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.Correspondence].permission,
    // permission: () => true,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.Asset].path,
    element: <OhsAssetRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.Asset].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.Noticeboard].path,
    element: <OhsNoticeBoardRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.Noticeboard].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.Onboardings].path,
    element: <OhsOnboardingRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.Onboardings].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.LinkaccessXsi].path,
    element: <OhsLinkAccessXsiRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.LinkaccessXsi].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.Xsi].path,
    element: <OhsXsiRoutes />,
    permission: OhsPrivateModuleRouteList[ModuleUrl.Xsi].permission,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.ResourceCentre].path,
    element: <OhsResourceCentreRoutes />,
    permission: () => true,
  },
  {
    path: OhsPrivateModuleRouteList[ModuleUrl.FeedBack].path,
    element: <OhsFeedBackForm />,
    permission: () => true,
  },
];

export default function OhsPrivateModuleRoutes() {
  const [user, setUser] = React.useState<OhsUser | null>(null);
  const localUser = OhsUserLocalServices.getLocalOhsUser();
  if (user)
    (window as any).privateRoot = {
      user,
      compareStrings,
      CurrencyCodes,
    };

  const getOhsUserEffect = async () => {
    const latestUser = await getOhsUserLocalAndRemote();

    if (latestUser) {
      setUser(latestUser);
    }
  };
  React.useEffect(() => {
    const token = Cookies.get(OhsSessionToken) || '';

    if (token) {
      getOhsUserEffect();
    }
  }, []);

  const displayInvalidPage = () => {
    if (user && user._id) return <OhsNoAccessPage />;
    return '';
  };

  return (
    <OhsRequireAuth>
      {user?._id && localUser?._id && (
        <ThemeProvider theme={OhsTheme(user)}>
          <OhsDashboardBars />
          <Suspense fallback={<OhsBackdrop />}>
            <Routes>
              {ROUTES.map((route: any) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.permission(user) === true ? route.element : displayInvalidPage()}
                />
              ))}
              <Route path="*" element={<OhsDefaultRoute />} />
            </Routes>
          </Suspense>
          <OhsMfaRequire />
        </ThemeProvider>
      )}
    </OhsRequireAuth>
  );
}
